import logo from './logo.svg';
import './App.css';
import { useState } from 'react';

let soundOn = false;
let audio = new Audio("pink.mp3");
audio.loop = true;

function App() {
  const [buttonText, setButtonText] = useState("Laster inn lydfil..."); 
  const [disabledState, setDisabledState] = useState(true); 
  const changeText = (text) => setButtonText(text);
  const changeDisabledState = (state) => setDisabledState(state);

  audio.addEventListener("loadedmetadata", (event) => { // can not use canplay on ios, so checking metadata
      changeDisabledState(false);
      changeText("Start lyd");
  });

  function toggleSound()
  {
    if(soundOn)
    {
      audio.pause();
      soundOn = false;
      console.log("stopping sound");
      changeText('Start lyd');
    }
    else
    {
      audio.play();
      soundOn = true;
      console.log("starting sound");
      changeText('Stopp lyd');
    }
  }
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Pink noise
        </p>
        
        <button className='startbutton' disabled={disabledState} onClick={toggleSound}>{buttonText}</button>

      </header>
    </div>
  );
}

export default App;
